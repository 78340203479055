<template>
  <div v-if="canAccess('cobranzas_pago_final')">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-md-4">
        <div class="card summary">
          <span class="title">Semanal</span>
          <span class="detail">Pagos finalizados en la semana</span>
          <span class="count visitors" style="font-size: 20px;">{{ assignments_week }}</span>
        </div>
      </div>
      <div class="p-col-12 p-md-4">
        <div class="card summary">
          <span class="title">Mensual</span>
          <span class="detail">Pagos finalizados en el mes</span>
          <span class="count purchases" style="font-size: 20px;">{{ assignments_month }}</span>
        </div>
      </div>
      <div class="p-col-12 p-md-4">
        <div class="card summary">
          <span class="title">Total</span>
          <span class="detail">Acumulado de pagos finales</span>
          <span class="count revenue" style="font-size: 20px;">{{ assignments }}</span>
        </div>
      </div>
    </div>
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-md-2 p-mt-2">
          <span class="p-float-label">
              <InputText id="enroll" v-model="search" @keyup.enter="filter()"/>
              <label for="enroll">Buscar por</label>
          </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df">
                        <Calendar id="dateInit" :showIcon="true" v-model="dateInit" dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2020:2050" :maxDate="dateFinish" @date-select="filter"/>
                        <label for="dateInit">Fecha Desde</label>
                    </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
         <span class="p-float-label df">
                        <Calendar id="dateFinish" :showIcon="true" v-model="dateFinish" dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2020:2050" :maxDate="dateFinishMax" @date-select="filter"
                                  :minDate="dateInit"/>
                        <label for="dateFinish">Fecha Hasta</label>
                    </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2" v-if="isAdmin">
                                <span class="p-float-label df">
                                    <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"
                                              @change="filter();subsidiary=null"/>
                                    <label for="country">País</label>
                                </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-if="canAccess('cobranzas_admin')">
                                <span class="p-float-label df">
                                    <Dropdown id="subsidiary" v-model="subsidiary" :options="subsidiaries" optionLabel="name"
                                              @change="filter"/>
                                    <label for="subsidiary">Filial</label>
                                </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-if="canAccess('cobranzas_admin')">
                                <span class="p-float-label df">
                                    <Dropdown id="executive_account" v-model="executive_account" :options="executives" optionLabel="executive"
                                              @change="filter"/>
                                    <label for="executive_account">Ejecutivo</label>
                                </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2" >
                                <span class="p-float-label df">
                                    <Dropdown id="day_payment" v-model="day_payment" :options="daysPayments" optionLabel="day"
                                              @change="filter"/>
                                    <label for="day_payment">Dia Cobro</label>
                                </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
                                <span class="p-float-label df">
                                    <Dropdown id="typeAccount" v-model="typeAccount" :options="typeAccounts"
                                              @change="filter"/>
                                    <label for="typeAccount">Tipo Cuenta</label>
                                </span>
      </div>

      <div class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df">
                        <Calendar id="dateInit" :showIcon="true" v-model="dateCobInit" dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2020:2050" :maxDate="dateCobFinish" @date-select="filter"/>
                        <label for="dateInit">Fecha Cobro Desde</label>
                    </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
         <span class="p-float-label df">
                        <Calendar id="dateFinish" :showIcon="true" v-model="dateCobFinish" dateFormat="dd/mm/yy"
                                  :yearNavigator="true" yearRange="2020:2050" @date-select="filter"
                                  :minDate="dateCobInit"/>
                        <label for="dateFinish">Fecha Cobro Hasta</label>
                    </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
         <span class="p-float-label df">
           <Dropdown id="current_fee_status" v-model="current_fee_status" :options="status_fees" @change="filter"/>
           <label for="dateFinish">Estado Cuota Vigente</label>
         </span>
      </div>


      <div class="p-col-12 p-md-2 p-mt-2" >
        <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                @click="reload"/>
      </div>
    </div>
   <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white">
            <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 350px;width: 100%; overflow-x: auto;">
            <DataTable :value="viewData" :paginator="true" dataKey="id"
                       class="p-datatable-sm p-datatable-striped"
                       style=""
                       :rowClass="getColorClass"
                        v-model:selection="currentItem" selectionMode="single"
                        @row-select="selectData($event)"
                        paginatorPosition="bottom"
                        :lazy="true"
                        :totalRecords="page.total"
                        :rows="page.per_page"
                        :loading="loading"
                        @page="onPage($event)"
                        v-model:expandedRows="expandedRows">
              <Column :expander="true" headerStyle="width: 3rem" />
              <Column header="No. Matrícula" headerStyle="text-align: center;width:80px">
                <template #body="slotProps">
                  <div>
                    <router-link :class="getColorClass(slotProps.data)"
                        :to="'/raise-money/detail-payments-finish/'+b64EncodeUnicode(slotProps.data.sequential)+'/'+slotProps.data.processed">
                      {{ slotProps.data.sequential }}
                    </router-link>
                  </div>
                </template>
              </Column>
              <Column header="Ejecutivo Cuenta" headerStyle="text-align: center;width:180px" >
                <template #body="slotProps">
                  <div class="center">{{ slotProps.data?.exec_account }}
                  </div>
                </template>
              </Column>
              <Column header="Cédula Titular" headerStyle="text-align: center;width:80px">
                <template #body="slotProps">
                  <div class="center">{{ slotProps.data.contract?.holder?.dni }}</div>
                </template>
              </Column>
              <Column header="Nombre Titular" headerStyle="text-align: center;width:160px">
                <template #body="slotProps">
                  <div class="center">{{ slotProps.data.contract?.holder?.name }}
                    {{ slotProps.data.contract?.holder?.last_name }}
                  </div>
                </template>
              </Column>
              <Column header="Tipo" headerStyle="text-align: center;width:150px">
                <template #body="slotProps">
                  {{ slotProps.data.type_enrollment }}
                </template>
              </Column>
              <Column header="Saldo" headerStyle="text-align: center;width:100px">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney((slotProps.data.plan?.total_value??0)-slotProps.data.amount_paid) }}</div>
                </template>
              </Column>
              <Column header="Valor Total" headerStyle="text-align: center;width:100px">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney(slotProps.data.plan?.total_value) }}</div>
                </template>
              </Column>
              <Column header="Valor Cuota" headerStyle="text-align: center;width:100px">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney(slotProps.data.amount_fee) }}</div>
                </template>
              </Column>
              <Column header="Monto Cobrado" headerStyle="text-align: center;width:100px">
                <template #body="slotProps">
                  <div class="center">{{ formatMoney(slotProps.data.amount_paid) }}</div>
                </template>
              </Column>
              <Column header="Porcentaje Recaudo" headerStyle="text-align: center;width:100px">
                <template #body="slotProps">
                  <div class="center">{{ parseFloat((slotProps.data.amount_paid*100)/slotProps.data.plan?.total_value??1).toFixed(2) }}%</div>
                </template>
              </Column>
              <Column header="Día Cobro" headerStyle="text-align: center;width:100px">
              <template #body="slotProps">
                <div class="center">{{ getDay(slotProps.data.contract?.date_first_payment) }}</div>
              </template>
              </Column>
              <Column header="Día acordado pago" headerStyle="text-align: center;width:100px">
              <template #body="slotProps">
                <div class="center">{{ slotProps.data.processeds.agreements[0]?.date_agreement??'-' }}</div>
              </template>
              </Column>
              <Column header="Estado Cuota Vigente" headerStyle="text-align: center;width:100px">
                <template #body="slotProps">
                  <div class="center">{{ slotProps.data.current_status_fee??'-' }}</div>
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="orders-subtable" v-if="slotProps.data.payments">
                  <h5>Pagos de matrícula</h5>
                  <DataTable :value="slotProps.data.payments" responsiveLayout="scroll" row-hover="false">
                    <Column header="Tipo de pago" sortable headerStyle="text-align: center;">
                      <template #body="slotSubProps">
                        <div class="center">{{ getTypePayment(slotSubProps.data.type_payment) }}</div>
                      </template>
                    </Column>
                    <Column field="status" header="Estado" sortable headerStyle="text-align: center;"></Column>
                    <Column field="type_transaction" header="Tipo de transacción" sortable headerStyle="text-align: center;"></Column>
                    <Column header="Valor" headerStyle="text-align: center;">
                      <template #body="slotSubProps">
                        <div class="center">{{ formatMoney(slotSubProps.data.value) }}</div>
                      </template>
                    </Column>
                    <Column header="Valor pagado" headerStyle="text-align: center;">
                      <template #body="slotSubProps">
                        <div class="center">{{ formatMoney(slotSubProps.data.value_paid) }}</div>
                      </template>
                    </Column>
                    <Column header="Fecha de pago" headerStyle="text-align: center;">
                      <template #body="slotSubProps">
                        <span v-if="slotSubProps.data.date_payment">{{ $filters.dateFormat(slotSubProps.data.date_payment)}}</span>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>
            </div>
          </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";
import service from "../../service/raiseMoney.service";
import moment from "moment";
import mw from "../../service/mw.service";

export default {
  mixins: [bouncer],
  name: "RaiseMoneyList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  components: {

  },
  data() {
    return {
      current_fee_status:null,
      typeAccount:null,
      itemSelect:null,

      day_payment:null,
      page: {
        page: 1,
        per_page: 9,
        total: 0,
        order: "desc"
      },
      assignments: 0,
      assignments_month: 0,
      assignments_week: 0,
      search: '',
      dateFinish: '',
      dateFinishMax: '',
      dateInit: '',
      dateCobInit:'',
      dateCobFinish:'',
      viewData: [],
      loading: false,
      currentItem: null,
      params: {},
      country: null,
      subsidiary: null,
      executive_account:null,
      countries: [],
      expandedRows: [],
      typeAccounts:[
          'Opcional',
          'Opcional por Novedad',
          'Primera Cuota',
          'Obligatoria'
      ],
      status_fees:[
        "NO COBRADO",
        "PARCIALMENTE NO COBRADO",
        "PARCIALMENTE COBRADO",
        "COBRADO"
      ]
    }
  },
  methods: {
    getColorClass(data){



      var classCss=null;
      if(data.type_enrollment === 'Opcional'){
        classCss= 'typeA';
      }
     if(data.type_enrollment === 'Opcional por Novedad'){
       classCss= 'typeB';
     }
      if(data.type_enrollment === 'Primera Cuota'){
        classCss= 'typeC';
      }
      if(data.type_enrollment === 'Obligatoria'){
        classCss= 'typeD';
      }

      if(data.enrollment_color?.points==1 && data.enrollment_color?.pay_actually!='N/A'){
        classCss= 'typeE';
      }else{
        if( data.enrollment_color?.monto_pagado >= data.enrollment_color?.valor_a_pagar){
          classCss= 'typeF';
        }else{
          if( data.have_payment_fee_major){
            classCss= 'typeG';
          }else{
            if(data.last_type_payment_rb){
              classCss= 'typeH';
            }else{
              if( data.type_enrollment === 'Obligatoria' && data.enrollment_color?.pay_actually=='N/A'){
                classCss= 'typeI';
              }
            }
          }
        }
      }

     return classCss;
    },
    getDay(date){
      return moment(date).format("DD")
    },
    b64EncodeUnicode(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
    },
    reload(){
      this.search = '';
      const date = new Date();
      this.dateFinish =  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
      this.dateFinishMax=  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
      this.dateInit = new Date(date.getFullYear(), 0, 1);
      this.country = null;
      this.subsidiary = null;
      this.executive_account = null;
      this.page.page = 1;
      this.typeAccount=null;
      this.dateCobInit=null;
      this.dateCobFinish=null;
      this.current_fee_status=null,
      this.filter();
    },
    onPage(event) {
      this.page.page = event.page + 1;
      this.loadEnrollments()
    },
    selectData() {
      this.$router.push('/raise-money/detail-payments-finish/' + this.b64EncodeUnicode(this.currentItem.sequential)+'/'+this.currentItem.processed);
    },
    filter() {
      this.loading = true;
      this.page.page = 1
      this.loadEnrollments()
    },
    formatMoney(val) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(val);
    },
    sumRecaudations(payments) {
      return  payments
          .map(item => item.value_paid)
          .reduce((prev, curr) => prev + curr, 0);
    },
    loadEnrollments() {
      this.viewData = [];
      this.loading = true;


      const config = {
        ...this.page,
        ...{q: this.search},
        date_init: this.dateInit ? moment(this.dateInit).format("YYYY-MM-DD") : undefined,
        date_finish: this.dateFinish ? moment(this.dateFinish).format("YYYY-MM-DD") : undefined,
        date_cob_init: this.dateCobInit ? moment(this.dateCobInit).format("YYYY-MM-DD") : undefined,
        date_cob_finish: this.dateCobFinish ? moment(this.dateCobFinish).format("YYYY-MM-DD") : undefined,
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        executive: this.executive_account?.id,
        day_payment: this.day_payment?.id??undefined,
        type_account: this.typeAccount??undefined,
        current_fee_status: this.current_fee_status??undefined
      };
      service.getPaymentFinish(null, config).then(x => {
        this.viewData = x.data.result.data;
        this.page.total = x.data.result.total;
        this.assignments = x.data.assignments;
        this.assignments_month = x.data.assignments_month;
        this.assignments_week = x.data.assignments_week;
      }).finally(() => this.loading = false);
      mw.getMWSELiq().then(x => {
        this.countries = x.data
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      })
    },
    getTypePayment(type_payment) {
      if(type_payment === 'INSCRIPTION') {
        return 'Inscripción';
      } else if (type_payment === 'FEE') {
        return 'Cuota';
      } else {
        return type_payment;
      }
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      return this.country ? this.country.subsidiaries : [];
    },
    executives() {
      return this.country ? this.country.executives : [];
    },
    daysPayments(){
      var days=[];
      for (let idx=1;idx<=31;idx++){
        days.push({id:idx,day:idx});
      }
      return days
    }
  },
  mounted() {
    if (this.canAccess('cobranzas_pago_final')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Lista de Matriculas con pagos finales', url: "javascript:void(0);"},
      ]);
    }

    const date = new Date();
    this.dateFinish =  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
    this.dateFinishMax=  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
    this.dateInit = new Date(date.getFullYear(), 0, 1);
    this.loadEnrollments();


    document.getElementById('divCard').addEventListener(
        'scroll',
        function()
        {
          var scrollTop = document.getElementById("divCard").scrollLeft;
          if (scrollTop == 0) {
            document.getElementsByClassName("p-paginator")[0].style.cssText="margin-left:0px;width:100%";
          } else {
            document.getElementsByClassName("p-paginator")[0].style.cssText="margin-left:"+(document.getElementById("divCard").scrollLeft) +"px;width:100%";
          }
        },
        false
    );
  }
}
</script>

<style>
.typeA{
  color: #1b1e21!important;
  font-weight: 500;
}
.typeB{
  color: #1b1e21!important;
  font-weight: 500;
}
.typeC{
  color: #0288d1!important;
  font-weight: 500;
}
.typeD{
  color: #1b1e21!important;
  font-weight: 500;
}
.typeE{
  color: green!important;
  font-weight: 500;
}
.typeF{
  color: deeppink!important;
  font-weight: 500;
}
.typeG{
  color: orangered!important;
  font-weight: 500;
}
.typeH{
  color: mediumpurple!important;
  font-weight: 500;
}
.typeI{
  color: darkblue!important;
  font-weight: 500;
}


.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}

.p-message-wrapper > span {
  display: none !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  text-align: center!important;
}

.p-datatable table {
  table-layout: auto;
}

.table-responsive {
  overflow-x: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.thC {
  background: #f8f9fa !important;
}

.tdC {
  padding: 4px !important;
  text-align: center;
}
</style>
